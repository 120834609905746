// src/components/Hero.js
import React from 'react';

function Hero() {
  return (
    <section className="hero text-center section">
      <h1>Cross Border Payments powered by Crypto</h1>
      <p>ChainlyPay is dedicated to building new age solutions to cross border payments at all layers.</p>
      <p>Whether that is payee management, streamlining the movement of funds, or speeding up commercial settlement, we can build it.</p>
    </section>
  );
}

export default Hero;
