// src/components/Features.js
import React from 'react';

function Features() {
  const features = [
    { title: 'Fast', description: 'Near instantaneous payments' },
    { title: 'Secure', description: 'Secured by the blockchain' },
    { title: 'Reliable', description: 'Available anytime' },
  ];

  return (
    <section id="features" className="features section bg-light">
      <div className="container text-center">
        <h2>Enable real-time multicurrency payments anywhere at anytime</h2>
        <div className="feature-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
