// src/components/CTA.js
import React from 'react';

function CTA() {
  return (
    <section className="cta text-center section">
      <h2>Coming soon</h2>
    </section>
  );
}

export default CTA;
