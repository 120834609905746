// src/components/Navbar.js
import React from 'react';

function Navbar() {
  return (
    <nav className="container">
      <div className="navbar">
        <h2 className="logo">ChainlyPay</h2>
        <ul className="nav-links">
          <li><a href="#features">Use Cases</a></li>
          <li><a href="#features">Features</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
