// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer text-center section bg-light">
      <p>© 2024 ChainlyPay. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
